import React from "react";
import { Link } from "react-router-dom";
const Home = () => {
  return (
    <div className="dhiwise-navigation bh-red-500">
      <h1>Homepage</h1>
      <ul>
        <li>
          <Link to="/landingpage01educator">Landingpage01Educator</Link>
        </li>
        <li>
          <Link to="/landingpage02learners">Landingpage02Learners</Link>
        </li>
      </ul>
    </div>
  );
};
export default Home;
