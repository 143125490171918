import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "pages/Home";
import NotFound from "pages/NotFound";
const Landingpage02Learners = React.lazy(
  () => import("pages/Landingpage02Learners"),
);
const Landingpage01Educator = React.lazy(
  () => import("pages/Landingpage01Educator"),
);
const ProjectRoutes = () => {
  return (
    <React.Suspense fallback={<>Loading...</>}>
      <Router>
        <Routes>
          <Route path="/" element={<Landingpage01Educator />} />
          <Route path="*" element={<NotFound />} />
          <Route
            path="/landingpage01educator"
            element={<Landingpage01Educator />}
          />
          <Route
            path="/landingpage02learners"
            element={<Landingpage02Learners />}
          />
        </Routes>
      </Router>
    </React.Suspense>
  );
};
export default ProjectRoutes;
